import React from 'react';
import { bool, array } from 'prop-types';
import loadable from '@loadable/component';

import { ALL_CARDS_ROUTE, CREATE_ROUTE, SUPPORT_PROBLEM } from 'constants/navigation';

import { LOYALTY_CARD_NAV, ADD_NAV, BUG } from 'constants/font-awesome';
import useStyles from './styles';

const LoyaltyCardsView = loadable(() => import('views/loyalty-cards'));
const Page = loadable(() => import('components/page'));
const ApplicationFooter = loadable(() => import('components/footer/application-footer'));
const LazyBanner = loadable(() => import('components/banners/in-app-banners/two'));

const Typography = loadable(() => import('@material-ui/core/Typography'));

const title = 'Work with your Loyalty Cards';
const message = 'Scan Share, add Loyalty Redeem rewards';

const SuspendedLoyaltyCardsPage = ({
  canAdd = false,
  passes = [],
  hasSub = false,
}) => {
  const styles = useStyles();

  return (
      <Page
        title={title}
        description={message}>
        <LazyBanner/>
          <Typography variant="h2" component="h1" className={styles.heading} align="center">
            {title}
          </Typography>
          <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
            {message}
          </Typography>
          <LoyaltyCardsView passes={passes} canAdd={canAdd} />
          <ApplicationFooter
            navItems={canAdd ? [
              { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
              { route: CREATE_ROUTE, icon: ADD_NAV, message: 'Add' },
              { route: SUPPORT_PROBLEM, icon: BUG, message: 'Get Support' },
            ] : [
              { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
              { route: SUPPORT_PROBLEM, icon: BUG, message: 'Get Support' },
            ]}
            currentItem="Cards"
            hasSubscription={hasSub}
            />
      </Page>
  );
};

SuspendedLoyaltyCardsPage.propTypes = {
  canAdd: bool.isRequired,
  passes: array,
  hasSub: bool,
};

export default SuspendedLoyaltyCardsPage;
